<template>
  <div id="base" class="drop">
    <h1 class="hd">{{ msg }}</h1>
    <p>dragType: {{ dragType }}</p>
    <p>dropType: {{ dropType }}</p>
  </div>
</template>

<script>
import { gsap } from "gsap";
import { ExpoScaleEase, RoughEase, SlowMo } from "gsap/EasePack";
import { CSSRulePlugin } from "gsap/CSSRulePlugin";
import { Draggable } from "gsap/Draggable";
import { EaselPlugin } from "gsap/EaselPlugin";
import { MotionPathPlugin } from "gsap/MotionPathPlugin";
import { PixiPlugin } from "gsap/PixiPlugin";
import { TextPlugin } from "gsap/TextPlugin";
import { ScrollToPlugin } from "gsap/ScrollToPlugin";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import { CustomEase } from "gsap/CustomEase";
import { DrawSVGPlugin } from "gsap/DrawSVGPlugin";
import { Flip } from "gsap/Flip";
import { GSDevTools } from "gsap/GSDevTools";
import { InertiaPlugin } from "gsap/InertiaPlugin";
import { MorphSVGPlugin } from "gsap/MorphSVGPlugin";
import { MotionPathHelper } from "gsap/MotionPathHelper";
import { Physics2DPlugin } from "gsap/Physics2DPlugin";
import { PhysicsPropsPlugin } from "gsap/PhysicsPropsPlugin";
import { ScrambleTextPlugin } from "gsap/ScrambleTextPlugin";
import { SplitText } from "gsap/SplitText";
import { CustomBounce } from "gsap/CustomBounce";
import { CustomWiggle } from "gsap/CustomWiggle";
gsap.registerPlugin(CSSRulePlugin, Draggable, EaselPlugin, MotionPathPlugin, PixiPlugin, TextPlugin, ScrollToPlugin, ScrollTrigger, CustomEase, DrawSVGPlugin, Flip, GSDevTools, InertiaPlugin, MorphSVGPlugin, MotionPathHelper, Physics2DPlugin, PhysicsPropsPlugin, ScrambleTextPlugin, SplitText, CustomBounce, CustomWiggle, ExpoScaleEase, RoughEase, SlowMo);

export default {
  name: "Base",
  data:function(){
    return {
      type: "Base",
      vueInstance: "",
      dragInstance: "",
      isDraggable: true,
      dragType: this.dragTypeProp,
      dropType: this.dropTypeProp,
    }
  },
  props: {
    msg: {
      type: String,
      default: "Base Component"
    },
    dragTypeProp: {
      type: Array,
      default(){ return ["text"] }
    },
    dropTypeProp: {
      type: Array,
      default(){ return ["text"] }
    },
  },
  methods: {
    Drop: function(dragElem, dropElem) {
      let vmDrag = dragElem.__vue__;
      let vmDrop = dropElem.__vue__;
      let valid = this.isValid(vmDrag.dragType,vmDrop.dropType);
      console.log("Drop Valid = ", valid);
      if( valid ){
        console.log("This is a valid drop target.");
        console.log("DragType: ",...vmDrag.dragType);
        console.log("DropType: ",...vmDrop.dropType);
        //handleValidDrop(){}
        //valid drop effect
        gsap.fromTo(dropElem, {opacity:1}, {duration: 0.1, opacity:0, repeat:3, yoyo:true});  
      }else{
        console.log("NOT a valid drop target!");
      } 
    },
    isValid: function(listA, listB) {
	    let v = false;
	    listA.forEach(function (type) {
		  if (listB.includes(type)) {
			    v = true;
		    }
	    });
	      return v;
      }, 
    Start: function(dragElem, dropElem) {
      console.log("vm.Start called from Draggable.onDragStart"); 
      console.log(dragElem, dropElem); 
    },
    Drag: function(dragThis, dropElem) {
      
         if (dragThis.hitTest(dropElem)) {
           dropElem.classList.add("highlight");
         } else {
           dropElem.classList.remove("highlight");
         }
       },
       
  },
  //computed: {},
  created() {
    this.vueInstance = this;
    },
 //START OF MOUNTED   
  mounted() {
    //get reference to the Vue Instance ( vm ) to access data & methods
    //while inside the DRAGGABLE SCOPE where 'this' refers to the dragging instance 
    let vm = this.vueInstance;
    //DRAGGABLE METHODS GO HERE
    Draggable.create("#base", {
      //element must have CSS {position: relative;}
      zIndexBoost: true,
      onClick: function(){ 
        
      },
      onDragStart: function(){
        //call Start on vm methods
        vm.Start();
      },
      onDrag: function(){
        let allDrops = document.querySelectorAll(".drop");
        var i = allDrops.length;
		    while (--i > -1) {
           //vm is Vue instance to access data & methods from inside Draggabe
           vm.Drag(this, allDrops[i]); 
       }
      },
      onDragEnd:function(){
        console.log('drag - End');
        let allDrops = document.querySelectorAll(".drop");
        var i = allDrops.length;
		    while (--i > -1) {
          if (this.hitTest(allDrops[i])) {
           //vm is Vue instance to access data & methods from inside Draggabe
           vm.Drop(this.target, allDrops[i]);
         } 
       }
      },
      onPress:function(){
        //console.log('drag - Press');
        },
      });//END OF DRAGGABLE SECTION
    this.dragInstance = Draggable.get("#base");
  },
//END OF MOUNTED SECTION
  updated() {
    console.log('vue updated');
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
#base{
  user-select: none;
  background-color: blue;
  color:white;
  margin: auto;
  padding: 20px;
  width: 400px;
  position: relative;
}
.highlight {
  border: 4px solid yellow;
}


</style>
