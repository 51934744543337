<template>
  <div class="bc">
    <Base msg="Base Tile 1" :dragTypeProp="['number']" :dropTypeProp="['number']"/>
    <Base msg="Base Tile 2" :dragTypeProp="['text']" :dropTypeProp="['number']"/>
    <Base msg="Base Tile 3" :dragTypeProp="['number']" :dropTypeProp="['text']"/>
    <Base msg="Base Tile 4" />
  </div>
</template>

<script>
// @ is an alias to /src


import Base from "@/components/BaseTile.vue";

export default {
  name: "BC",
  components: {
    Base,
  },
};
</script>

<style scoped>
*{
  box-sizing: border-box;
}
</style>